angular.module('config', [])

    .constant('PROPERTIES', {
        SHOW_VERSION: true,
        VERSION: '3.16.1',
        DEBUG: false,
        PRODUCTION: true,
        IDLE_TIMEOUT: 840,
        WARNING_TIMEOUT: 60
    })

;
